@use '../../styleLibrary/glibrary';

$hover-color: glibrary.$main-green-blue-color;

.SkillWithPercentageValue {
  display: block;
  margin-top: 10px;

  &:hover {
    .ProgressContainer {
      .Progress:before {
        width: 100%;
      }
    }
  }

  h4 {
    margin: 0;
  }

  .ProgressContainer {
    width: 100%;
    height: .7rem;
    box-sizing: border-box;
    border: 1px solid white;

    .Progress {
      position: relative;
      height: 100%;
      background-color: white;

      &:before {
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 0;
        height: 100%;
        background-color: $hover-color;
        transition: 0.2s ease-in-out width;
        content: "";
      }
    }
  }
}
