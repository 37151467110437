@use '../../styleLibrary/glibrary';

$media-max-width: glibrary.$media-max-width-basic-info-split;
$media-min-width: glibrary.$media-min-width-basic-info-split;

.BasicInformationContainer {
  grid-area: basic-info;
  padding: 1.4rem 0;
  background-color: glibrary.$basic-info-bg-color;
  color: glibrary.$bright-color;

  @media screen and (max-width: $media-max-width) and (min-width: $media-min-width) {
    display: grid;
    grid-column-gap: 0.5rem;
    grid-template-columns: 1fr 1fr;
    padding: 1.4rem 0.5rem;
  }
}