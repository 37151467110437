@use '../../styleLibrary/glibrary';

.MainContentSection {
  margin-top: 15px;

  & .Title {
    padding: 5px 0 5px 15px;
    margin: 0 0 10px 0;
    border-top: glibrary.$dark-color solid 1px;
    border-bottom: glibrary.$dark-color solid 1px;
    text-align: left;
    font-size: 1.5rem;
  }
}
