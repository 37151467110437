.LanguageSelector {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  margin-bottom: 1rem;
  gap: 20px;

  .LanguageItem {
    width: 60px;
    height: 30px;
    padding: 0;
    border: 0;
    outline: none;

    .Image {
      width: 100%;
      height: 100%;
      object-fit: cover;
      cursor: pointer;
    }

    &:focus-visible,
    &:focus {
      box-shadow: 0 0 4px 1px white;
      outline: none;
    }
  }
}
