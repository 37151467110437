@use '../../styleLibrary/glibrary';

.BasicInfoSection {
  margin-bottom: 1rem;

  .Title {
    display: flex;
    justify-content: center;
    align-content: center;
    padding: 8px;
    margin: 0;
    background-color: glibrary.$dark-color;
    text-align: center;
    font-size: 1.3rem;
  }

  .Content {
    padding: 10px 8px;
  }
}