$facebook-color: #3d5b99;
$linkedin-color: #0077b5;
$goldenline-color: transparent;

@mixin social-image() {
  width: 1.3rem;
  height: 1.3rem;
  padding: 0.2rem;
  border-radius: 10%;
}

.SocialMedia {
  display: flex;
  flex-direction: column;
  gap: .8rem;

  .SocialItem {
    display: flex;
    cursor: pointer;
    color: white;
    text-decoration: none;
    gap: .5rem;
    outline: none;

    &:focus,
    &:focus-visible,
    &:hover {
      outline: none;

      .SocialItemLabel {
        &:after {
          width: 100%;
        }
      }
    }
  }

  .SocialItemLabel {
    position: relative;
    margin: auto 0;

    &:after {
      position: absolute;
      bottom: -0.05rem;
      left: 50%;
      display: block;
      width: 0;
      height: 1px;
      background-color: white;
      transform: translateX(-50%);
      transition: width ease-in-out 0.15s;
      content: '';
    }
  }

  .Facebook {
    @include social-image();
    background-color: $facebook-color;
  }

  .LinkedIn {
    @include social-image();
    background-color: $linkedin-color;
  }

  .GoldenLine {
    @include social-image();
    background-color: $goldenline-color;
  }
}
