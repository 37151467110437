:root {
  --document-max-width: 1200px;
  --document-margin-top: 30px;
  --document-margin-bottom: 30px;

  --dark-light-color: #454545;
  --dark-color: #232323;
  --bright-color: white;
  --shadow-color: rgba(0, 0, 0, 0.75);
  --main-green-blue-color: rgb(12 135 95);
}
