@use '../../styleLibrary/glibrary';

$timeRangeAndWorkPlaceColor: #454545;
$hoverBackgroundColor: #eee;

$media-max-width: glibrary.$media-max-width-experience-item-split;

.ExperienceItem {
  position: relative;
  display: grid;
  grid-auto-columns: 1fr 2fr;
  grid-auto-rows: auto auto;
  grid-template-areas: "timeRange workPosition" "experiencePlace description";
  padding: 0.5rem 0 0.5rem 20px;
  column-gap: 0.8rem;
  transition: background-color ease-in-out 0.18s;
  row-gap: 0.4rem;

  &:hover {
    background-color: $hoverBackgroundColor;
  }

  .TimeRange {
    grid-area: timeRange;
    color: $timeRangeAndWorkPlaceColor;
    text-align: right;
    font-size: 0.8rem;
    font-weight: 300;
  }

  .WorkPosition {
    grid-area: workPosition;
    margin: 0;
    font-size: 1rem;
    font-weight: 700;
  }

  .ExperiencePlace {
    grid-area: experiencePlace;
    margin: 0;
    color: $timeRangeAndWorkPlaceColor;
    text-align: right;
    text-transform: uppercase;
    font-size: 1.2rem;
  }

  .Description {
    grid-area: description;
    margin: 0;
    font-size: 1rem;
    font-weight: 500;
  }

  .DescriptionAddon {
    padding-bottom: 0.2rem;
    font-weight: 700;
  }

  .Decorator {
    position: absolute;
    top: 50%;
    left: 5px;
    width: 3px;
    height: 80%;
    background-color: $timeRangeAndWorkPlaceColor;
    border-radius: 50%;
    transform: translateY(-50%);
  }

  @media screen and (max-width: $media-max-width) {
    grid-auto-columns: auto;
    grid-auto-rows: auto auto auto auto;
    grid-template-areas: "timeRange" "experiencePlace" "workPosition" "description";

    .ExperiencePlace {
      margin-bottom: 0.5rem;
      text-align: center;
    }

    .WorkPosition {
      margin-bottom: 0.5rem;
      text-align: left;
    }
  }
}
