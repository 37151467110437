.List {
  list-style-type: square;
  margin: 0.4rem 0;
  padding-left: 2.5rem;

  .ListItem {
    margin: 0.1rem 0 0.6rem 0.1rem;
    font-size: 1rem;
  }
}
