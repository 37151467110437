@use '../../styleLibrary/glibrary';

$media-max-width: glibrary.$media-max-width-document-split;

.Document {
  position: relative;
  display: grid;
  grid-template-areas: "basic-info main-content";
  grid-template-columns: 30% 70%;
  grid-template-rows: auto;
  max-width: 1200px;
  margin: glibrary.$document-margin-top auto glibrary.$document-margin-bottom auto;
  box-shadow: 0px 0px 10px 1px glibrary.$shadow-color;
  background-color: #fff;
}

@media screen and (max-width: $media-max-width) {
  .Document {
    grid-template-areas:
            "basic-info"
            "main-content";
    grid-template-columns: auto;
    grid-template-rows: auto auto;
    margin-top: 0;
  }
}