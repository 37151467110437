$dark-light-color: var(--dark-light-color);
$dark-color: var(--dark-color);
$bright-color: var(--bright-color);

$document-max-width: var(--document-max-width);
$document-margin-top: var(--document-margin-top);
$document-margin-bottom: var(--document-margin-bottom);

$shadow-color: var(--shadow-color);

$basic-info-bg-color: var(--dark-light-color);
$basic-info-bg-dark-color: var(--dark-color);
$basic-info-text-color: var(--bright-color);

$main-green-blue-color: var(--main-green-blue-color);

$media-max-width-document-split: 900px;
$media-max-width-basic-info-split: 900px;
$media-min-width-basic-info-split: 490px;
$media-max-width-experience-item-split: 500px;
