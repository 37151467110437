.TitleNameSurname {
  margin: 10px 0;
  text-align: center;
  font-size: 1.8rem;
}

.TitleWorkPlace {
  margin: 4px 0;
  text-align: center;
  font-size: 1rem;
  font-weight: 300;
}
